<template>
  <article v-if="exposureItems.length > 0" class="content-section">
    <header class="section-header"><h4 class="title">일정</h4></header>
    <div class="text-divider-container">
      <!-- text-divider-list -->
      <ol class="text-divider-list">
        <!-- text-divider-item -->
        <li v-for="(item, idx) in exposureItems" class="text-divider-item" :key="idx" @click="goLearn(item)">
          <div class="item-title">{{ timestampToDateFormat(item.objBgngDt, 'hh:mm') }} ~ {{ timestampToDateFormat(item.objEndDt, 'hh:mm') }}</div>
          <strong class="item-description">{{ item.objNm }}</strong>
        </li>
      </ol>
      <!-- //text-divider-list -->
    </div>
  </article>

</template>

<script>

import {trainScheduleSetup} from '@/assets/js/modules/train/train-setup';

export default {
  name: 'MobileTrainSchedule',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup: trainScheduleSetup
}
</script>
