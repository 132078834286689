<template>
  <article class="content-section">
    <header class="section-header">
      <h4 class="title">{{ title }} <span v-if="items.length > 0" class="count">{{ items.length }}</span></h4>
    </header>
    <div class="educourse-list-container">
      <!-- educourse-list -->
      <template v-if="!isLoading">
        <div v-if="items.length === 0" class="search-container">
          <div class="result-empty">
            <img src="@/assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">{{descTitle}} 연수가 없습니다.</p>
          </div>
        </div>
        <ul v-else class="educourse-list">
          <!-- educourse-list > list-item -->
          <li v-for="(item, index) in items" class="list-item" :key="index">
            <!-- educourse-list > educourse -->
            <div class="educourse">
              <div class="educourse-summary course-row">
                <article class="course-card">
                  <div class="course-image">
                    <a href="javascript:" class="image-link" @click="goLearn(item, session.itn)"></a>
                    <figure class="image-figure">
                      <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                      <img v-else-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                      <CourseThumb v-else
                                   :num="item.crseMstSn"
                      />
                    </figure>
                  </div>
                  <div class="course-content">
                    <div class="content-header">
                      <h5 class="title">
                        <a href="javascript:" class="title-link" @click="goLearn(item, session.itn)">{{ item.crseNm }}</a>
                      </h5>
                    </div>
                    <div class="content-footer">
                      <div class="content-meta">
                        <div class="d-flex ">
                          <p class="text">{{  item.orgnzNm }}</p>
                          <p class="text-extra">{{ manipulate(item, exItems, learns) }}</p>
                        </div>
                        <p class="text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd')}} - {{timestampToDateFormat(item.endDt, 'yyyy.MM.dd')}}</p>
                      </div>
                      <div v-if="item.crseMlg > 0" class="content-mileage">
                        <p class="text">마일리지 {{ item.crseMlg }}</p>
                      </div>
<!--                      <div class="course-icons">-->
<!--                        <div class="icons">-->
<!--                          <i class="icon-course-collective" title="집합연수"></i><i class="icon-course-book" title="교재"></i><i class="icon-course-cyber" title="사이버"></i>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <div class="course-actions">&nbsp;</div>
                </article>
              </div>
            </div>
          </li>
          <!-- educourse-list > list-item -->
        </ul>
      </template>
    </div>
  </article>
</template>

<script>

import CourseThumb from '@/components/apply/CourseThumb';
import {trainCoursesSetup} from '@/assets/js/modules/train/train-setup';

export default {
  name: 'MobileTrainCourses',
  components: {CourseThumb},
  props: {
    title: String,
    descTitle: String,
    trainType: String,
    items: Array,
    isScheduled: Boolean,
    isLoading: Boolean,
    learns: Array,
    exItems: Array
  },
  setup: trainCoursesSetup
}
</script>

<style scoped>

.lxp-mobile-layout .course-row .course-content .content-meta .text-extra {
  position: relative;
  font-size: 12px;
  font-family: var(--kb-font-KBFGTextB);
  color: var(--kb-primary) !important;
  margin-top: 4px;
  margin-left: 10px;
  line-height: 1.2;
}

</style>
