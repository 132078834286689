import {useRoute, useRouter} from 'vue-router';
import {
  dateToDatePart,
  getDDay,
  getTodayDate,
  timestampToDateFormat,
  timestampToDatePart,
} from '@/assets/js/util';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import {computed, reactive, ref, watch} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import {getListFunc, getValueByQuery} from '@/assets/js/ui.init';
import {
  ACT_GET_LEARN_LIST,
  ACT_GET_LEARN_OBJECT_SCHEDULE_LIST,
} from '@/store/modules/learn/learn';
import {goLearn} from '@/assets/js/modules/train/train-common';
import {useStore} from 'vuex';
import {ACT_GET_LEARN_HISTORY_LIST} from '@/store/modules/learn/learn';
import {ACT_GET_CRSE_DIST_COND} from '@/store/modules/course/course';

export const trainMainSetup = () => {
  const router = useRouter();
  const route = useRoute();
  const isLoading = ref(true);

  const paging = reactive({
    pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
    pageSize: 10,
    totalCount: 0
  });

  const menuTitle = computed(() => {

    console.log("1", route.path)
    if (route.path.indexOf('proceeding') > 0) {
      return '진행중 연수';
    } else if (route.path.indexOf('scheduled')>0) {
      return '예정된 연수';
    } else if (route.path.indexOf('finished')>0) {
      return '완료된 연수';
    }
    return '';
  });

  const trainType = computed(() => {
    console.log("2", route.path)
    if (route.path.indexOf('proceeding') > 0) {
      return 'proceeding';
    } else if (route.path.indexOf('scheduled')>0) {
      return 'scheduled';
    } else if (route.path.indexOf('finished')>0) {
      return 'finished';
    }
    return '';
  });

  const descTitle = computed(() => {
    console.log("3", route.path)
    if (route.path.indexOf('proceeding') > 0) {
      return '진행중인';
    } else if (route.path.indexOf('scheduled')>0) {
      return '예정된';
    } else if (route.path.indexOf('finished')>0) {
      return '완료된';
    }
    return '';
  });


  const isScheduled = computed(() => route.path.indexOf('/scheduled') > 0);
  const isProceeding = computed(() => route.path.indexOf('/proceeding') > 0);
  const isFinished = computed(() => route.path.indexOf('/finished') > 0);
  const dutyEduYn = computed(() => route.query.dutyEduYn);

  const items = ref([]);
  const schedules = ref([]);

  const search = reactive({
    objTargetYmd: getTodayDate('yyyy-mm-dd')
  });

  const getLearnList = () => {
    isLoading.value = true;
    const params = {...paging};
    if(isScheduled.value){
      params['scheduledYn'] = 'Y';
    }else if(isFinished.value){
      params['scheduledYn'] = 'F';
    }else{
      params['proceedingYn'] = 'Y';
      params['dutyEduYn'] = dutyEduYn.value;

      if(isProceeding.value) {
        let query = {};
        if(dutyEduYn.value) query.dutyEduYn = dutyEduYn.value;
        router.push({name : 'TrainProceeding', query: query});
      }
    }

    getListFunc(`learn/${ACT_GET_LEARN_LIST}`, params, items, paging, () => {
      if(isScheduled.value) items.value = items.value.map(x => ({...x, dday: getDDay(x.bgngDt)}));
      isLoading.value = false;
    });
  }

  const getLearnObjectScheduleList =  () => {
    // getListFunc(`learn/${ACT_GET_LEARN_OBJECT_SCHEDULE_LIST}`, {...search}, schedules, null);
    getListFunc(`learn/${ACT_GET_LEARN_OBJECT_SCHEDULE_LIST}`, {...search}, schedules, null);
  }

  const dayFunc = (year,month,day) => {
    search.objTargetYmd = `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;
    getLearnObjectScheduleList();
  }

  const learns = ref([]);
  const getLearnHistoryList = () => {
    getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
      learns.value = learns.value.filter(y => y.auditCfmtnCd === 'Y')
                                 .filter(y => y.fnshYn === 'Y')
                                 .map(x => x.crseMstSn);
    });
  }

  const exItems = ref([]);
  const getLearnExItemsList = () => {
    getListFunc(`course/${ACT_GET_CRSE_DIST_COND}`, {}, exItems, null, () => {
      exItems.value = exItems.value.
          filter(x => x.regCrseCondCdDcd === '2111003' || x.regCrseCondCdDcd === '2111004');
    });
  }

  watch(() => route.path, () => {
    if( ['TrainProceeding','TrainScheduled','TrainFinished'].includes(route.name) ){
      if (isProceeding.value){
        getLearnObjectScheduleList();
      }
    }

    getLearnList();
  });

  watch(() => route.query, () => {
    if(route.name === 'TrainProceeding') getLearnList();
  });


  if(isProceeding.value) getLearnObjectScheduleList();
  getLearnList();
  getLearnHistoryList();
  getLearnExItemsList();

  return {
    isLoading,
    trainType,
    descTitle,
    isScheduled,
    isProceeding,
    isFinished,
    items,
    paging,
    schedules,
    dayFunc,
    learns,
    exItems,
    menuTitle,
  }
}

export const trainScheduleSetup = (props) => {

  const currentYmd = dateToDatePart(new Date()).ymd;

  const scheduledDcds = [lrnObjTyCds.Evaluation, lrnObjTyCds.Collective, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn];

  const exposureItems = computed(() => {
    return props.items
        .filter(x => scheduledDcds.includes(x.lrnObjTyCd))
        .map(x => ({...x, objBgngYmd: timestampToDatePart(x.objBgngDt).ymd, objEndYmd: timestampToDatePart(x.objEndDt).ymd}))
        .filter(x =>  x.objBgngYmd === x.objEndYmd);
  });

  // const goSchdeuledLearn = item => {
  //   if (currentYmd === item.objBgngYmd) {
  //     router.push({name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'index' }, query: {learn: item.lrnObjDtlDistSn}})
  //   } else {
  //     router.push({name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'home' }})
  //   }
  // }

  return {
    currentYmd,
    exposureItems,
    goLearn,
    timestampToDateFormat
  }

}

export const trainCoursesSetup = () => {

  const store =  useStore();
  const router = useRouter();
  const route = useRoute();
  const {showMessage} = useAlert();
  const session = computed(() => store.state.auth.session);
  // col or row
  const mode = ref('col');
  const courseClass = computed(() => {
    if(mode.value === 'col'){
      return 'course-column';
    }else if(mode.value === 'row'){
      return 'course-row';
    }
    return '';
  });

  const applyModal = ref(false);
  const targetParams = ref({});
  const exStr = ref('');

  const showApplyDetail = (item) => {
    targetParams.value = item;
    applyModal.value = true;
  }

  const selectMode = (selected) => {
    mode.value = selected;
  };

  const filterDutyEduYn = ref(route.query.dutyEduYn);
  const toggle = ref(false);

  const filterSelect = () => {
    router.push({name : 'TrainProceeding', query: {dutyEduYn: filterDutyEduYn.value}});
  };

  const showScheduleAlert = (item) => {
    showMessage(timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') + ' 부터 연수를 하실 수 있습니다.');
  }

  const manipulate = (item, exItems, learns) => {
    const included = exItems.filter(y => y.crseMstSn === item.crseMstSn);
    let rtn = ''
    if (included.length > 0) {
      if (learns && learns.length > 0) {
        const existed = learns.filter(x => x === item.crseMstSn);
        if (existed.length > 0) {
          rtn = '';
        } else {
          // rtn = '시간외부여';
          rtn = '';
        }
      } else {
        // rtn = '시간외부여'
        rtn = '';
      }
    }
    return rtn;
  }

  return {
    session,
    mode,
    courseClass,
    applyModal,
    targetParams,
    toggle,
    filterDutyEduYn,
    filterSelect,
    showApplyDetail,
    selectMode,
    showScheduleAlert,
    goLearn,
    timestampToDateFormat,
    getThumbUrl,
    manipulate,
    exStr
  }
}

export const trainCourseElSetup = (props, {emit}) => {
  const store =  useStore();
  const {showMessage} = useAlert();
  const session = computed(() => store.state.auth.session);

  const showScheduleAlert = (item) => {
    showMessage(timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') + ' 부터 연수를 하실 수 있습니다.');
  }

  const toggleApplyDetail = (item) => {
    emit('showDetail', item);
  };

  const manipulate = (item, exItems, learns) => {
    const included = exItems.filter(y => y.crseMstSn === item.crseMstSn);
    let rtn = ''
    if (included.length > 0) {
      if (learns && learns.length > 0) {
        const existed = learns.filter(x => x === item.crseMstSn);
        if (existed.length > 0) {
          rtn = '';
        } else {
          // rtn = '시간외부여';
          rtn = '';
        }
      } else {
        // rtn = '시간외부여'
        rtn = '';
      }
    }
    return rtn;
  }

  return {
    session,
    toggleApplyDetail,
    showScheduleAlert,
    timestampToDateFormat,
    getThumbUrl,
    goLearn,
    manipulate
  }
}

