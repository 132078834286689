<template>
  <LxpMobileHeader :use-route-name="true" />
  <main class="kb-main" id="kb-myclass">
    <!-- main-content -->
    <div class="main-content main-component">
      <!-- calendar -->
      <TrainWeekCalendar
          :is-loading="isLoading"
          :day-func="dayFunc"
      />
      <!-- //calendar -->

      <MobileTrainSchedule
          v-if="isProceeding"
          :items="schedules"
      />

      <!-- content-section:진행중 연수 -->
      <MobileTrainCourses
          :title="menuTitle"
          :desc-title="descTitle"
          :train-type="trainType"
          :items="items"
          :is-scheduled="isScheduled"
          :is-loading="isLoading"
          :learns="learns"
          :exItems="exItems"
      />
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import TrainWeekCalendar from '@/components/train/TrainWeekCalendar';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileTrainSchedule from '@/components/train/mobile/MobileTrainSchedule';
import MobileTrainCourses from '@/components/train/mobile/MobileTrainCourses';
import {trainMainSetup} from '@/assets/js/modules/train/train-setup';

export default {
  name: 'MobileTrainProceeding',
  components: {
    MobileTrainCourses,
    MobileTrainSchedule, LxpMobileHeader, TrainWeekCalendar},
  setup: trainMainSetup
}
</script>
