import store from '@/store';
import router from '@/router';
import {MUT_TOGGLE_TRAIN_DETAIL} from '@/store/modules/train/train';
import {useAlert} from '@/assets/js/modules/common/alert';
import {timestampToDateFormat, winLearnOpen} from '@/assets/js/util';
// import ApiService from '@/assets/js/api.service';

const {showMessage} = useAlert();

export const toggleTrainDetail = (show, item) => {
    store.commit(`train/${MUT_TOGGLE_TRAIN_DETAIL}`, {show, item});
};


export const goLearn = (item, itn) => {
    if (item.bgngDt > new Date().getTime()) {
        showMessage(timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') + ' 부터 연수를 하실 수 있습니다.');
        // LMS_CRSE > 링크과정으로 설정된 경우 > LMS_CRSE_POLI 테이블의 URL로 window.open 시킴
    } else if (item.introDivCdDcd === '2030003') {
        if (itn) {
            if(confirm('은행내에서 외부과정을 접속하시려면 KB인터넷PC의\n[인터넷PC-연수시스템]바로가기 아이콘을 이용하시기 바랍니다\n\n가상인터넷PC 실행화면으로 이동하시겠습니까?')) location.href="https://kbintpc.kbstar.com";
        }else {
            winLearnOpen(item);
        }
    }else if(item.crseMstSn === 100434) { // Value-Up 신규과정 분기처리
         router.push({name: 'remindMain', params:{ distCrseSn: item.distCrseSn }});
    }else {
        router.push({name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'home' }});
    }
}

// function fncEnCode(param)
// {
//     // sjisbmoc
//
//     let encode = '';
//
//     for(let i=0; i<param.length; i++)
//     {
//         let len  = ''+param.charCodeAt(i);
//         let token = '' + len.length;
//         encode  += token + param.charCodeAt(i);
//     }
//
//     return encode;
// }

